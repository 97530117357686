import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import { 
    Button,
    Grid,
    IconButton,
    Typography, 
} from '@material-ui/core';
import ENUMS from '../api/ENUMS.json';
import { FieldType } from '../api/fieldTypes.js';


import {getBookings, getListings, setBookingStatus} from '../api/Oscar-api';
import Calendar from '../components/Calendar';
import LoadingModal from '../components/LoadingModal';

import CloseIcon from '@material-ui/icons/Close';
import IconBack from '@material-ui/icons/KeyboardArrowLeft';
import IconNext from '@material-ui/icons/KeyboardArrowRight';

import {
    BookingDayHeader,
    BookingsRangeDisplayFormat, 
    CalendarMonth,
    ParseDateTimeParameters,
    WPMonthlyBookingsDisplayFormat
} from '../utils/TimeFormats.js';
import FullPageModal from '../utils/FullPageModal';
import SelectDropDown from '../components/SelectDropDown';

import { isNativeWebview } from '../utils/IsNativeWebview';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const styles = {
    editButton: {
      color: '#1AA5A2',
      backgroundColor: 'white',
      boxShadow: 'none',
      '&:hover, &:focus': {
          backgroundColor: 'white',
      },
      border: '1px solid #1AA5A2'
    }, 
     toggleButton: {
        width: "100%",
        border: "1px solid #777",
        height: "45px",
        
    },
    toggleButtonActive: {
      width: "100%",
      border: "1px solid #777",
      height: "45px",
      backgroundColor:'#1AA5A2',
    },

    rightCornerButton: {
        position: 'absolute',
        top: '8px',
        right: '10px',
    },

    bookingsContainer: {
        marginTop: '24px',
    },

    upcomingBookingContainer: {
        border: '1px solid #efefef',
        padding: '12px',
        marginBottom: '16px',
    },

    bookingDetailsTopSection: {
        position: 'relative',
        padding: '16px',
        paddingTop: '80px',
        paddingBottom: '60px',
        marginBottom:'50px',
        backgroundColor: "#1AA5A2"
    },
    topSectionText: {
        color: 'white'
    },
    topColored: {
      paddingTop: '80px',
      backgroundColor: '#1AA5A2',
      paddingBottom: '8px',
  },
    guestProfileImage: {
        backgroundColor: 'white',
        borderRadius: '50px',
        position: 'absolute',
        right: '16px',
        bottom: '-38px',
        width: '75px',
        height: '75px',
    },
    bookingDetailsLowerSection: {
        backgroundColor: 'white',
        height: '360px',
        padding: '16px',
        paddingTop: '60px',
    },
    bookingDetailsProperty: {
        padding: '10px 0px',
        borderTop: '1px solid #efefef',
    },
    messageButton: {
        position: 'absolute',
        bottom: '16px',
        right: '16px',
    },
    calendarCarouselContainer: {
        marginLeft: '-16px',
        marginRight: '-16px',
        marginBottom: '24px',
        paddingLeft: '16px',
        height: '300px',
        borderBottom: '1px solid #efefef',
        width: 'auto',
        overflowX: 'scroll',
        whiteSpace: 'nowrap'
    },
    spaceCalendarContainer: {
        display: 'inline-block',
        marginRight: '16px',
    },
    calendarContainer: {
        marginBottom: '16px',
        width: '376px',
    },
    calendarContainerWrapper: {
        width: '246px',
        height: '230px',
        overflow: 'hidden',
    },
    guestImage: {
        width: '46px',
        height: '46px',
        borderRadius: '50px'
    },
    guestImageContainer: {
        textAlign: 'right'
    },
    link: {
      display: 'inline-block',
      marginLeft: '4px',
      textDecoration: 'underline',
      cursor:'pointer'
  }

}

const groupBy = value => {
    return (groups, current) => {
        if (!(current[value] in groups)) {
            groups[current[value]] = []
        }
        groups[current[value]].push(current)
        return groups
    }
}

function ListingCalendar(props) {
    return(
        <div style={{backgroundColor: 'white', height: '100%', overflowY: 'auto'}}>
            <div className="mainContent topPadded">
                <IconButton className="leftCornerButton" onClick={props.onClose}>
                    <IconBack/>
                </IconButton>
                <Button style={styles.rightCornerButton} color="primary"
                component={Link} to={`Availabilities/${props.listing.uuid}`}>
                    Edit Availabilities
                </Button>

                <Typography style={{marginBottom: '8px'}} variant="display1" color="primary">
                    Calendar
                </Typography>
                
                <Typography variant="body1" >
                    {props.listing.spaceType} on {props.listing.street}
                </Typography>
            </div>
            <div style={{overflow: 'auto' }}>
                <Calendar 
                    availabilities={props.listing.availabilities}
                    bookings={props.bookings}
                    maxMonthsToDraw={6}
                    fullSize={true}
                    onBookingDayClick={props.onBookingDayClick}
                />
            </div>
        </div>
    )    
}

function BookingDetails(props) {
    function externalLink(target, nativeTarget) {
        if (isNativeWebview()) {
            const message = JSON.stringify({
                action: "external_link",
                target: nativeTarget
            })
            window.ReactNativeWebView.postMessage(message);
        } else {
            window.location.href = target
        }
    }

    let strBookingName = props.booking.guest.first_name;
    if (strBookingName.substr(-1) !== 's'){
      strBookingName+="'s";   // ie. Peter's
    } else {
      strBookingName+="'";    // ie. Ross'
    }

    let strBookingType;
    if (props.booking.booking_type == 2){
      strBookingType="Monthly";
    } else {
      strBookingType = "Casual";
    }

    if (props.booking.approval_status === 1){
      strBookingName+=" Booking Request";
    } else {
      strBookingName+=" Booking";
    }
    
    let strApprovalCutOff = props.booking.create_date.clone().add(48,"hours").format("Do MMMM YYYY");
    //console.log(props.booking);

    return(
        <>
            <div style={styles.bookingDetailsTopSection}>
                <IconButton className="leftCornerButton" onClick={props.onClose}>
                    <IconBack style={styles.topSectionText} />
                </IconButton>

                <Typography style={styles.topSectionText} variant="display1" >
                  {strBookingName}
                </Typography>
                
                <Typography style={styles.topSectionText} variant="body1" >
                  Booking Ref#{props.booking.reference} - {strBookingType}
                </Typography>

               
                <Typography style={styles.topSectionText} variant="body1" >
                    {props.booking.parking_space.type} on {props.booking.parking_space.street_name}
                </Typography>
                
                {props.booking.guest.image_user !== null 
                ? <img style={styles.guestProfileImage} src={props.booking.guest.image_user.path} alt="user profile" />
                : <img style={styles.guestProfileImage} src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" alt="user profile" />}

                {props.booking.approval_status != 2 && <div style={{color:"#ffffff",cursor:'pointer',marginTop:'10px',textDecoration:'underline',fontWeight:'600'}}  onClick={() => props.history.push(`/Messages/${props.booking.reference}/owner/`)}>Contact Driver</div>}

            </div>

            <div className="sd-container">
              <div className="sd-details">

            <Grid style={styles.bookingDetailsProperty} container justify="space-between">
              <Grid><Typography variant="body1">Booking Status</Typography></Grid>
              <Grid><Typography variant="body1">{ENUMS.approval_status[props.booking.approval_status || 0]}</Typography></Grid>
           </Grid>

            {props.booking.booking_type == 2 &&
            <>
                <Grid style={styles.bookingDetailsProperty} container justify="space-between">
                    <Grid><Typography variant="body1">Start Date</Typography></Grid>
                    <Grid><Typography variant="body1">{props.booking.start.format("Do MMMM YYYY")}</Typography></Grid>
                </Grid>
                <Grid style={styles.bookingDetailsProperty} container justify="space-between">
                    <Grid><Typography variant="body1">End Date</Typography></Grid>
                    <Grid><Typography variant="body1">{props.booking.end ? props.booking.end.format(WPMonthlyBookingsDisplayFormat):"Ongoing"}</Typography></Grid>
                </Grid>
            </>
            }

            {props.booking.booking_type != 2 &&
            <>
                <Grid style={styles.bookingDetailsProperty} container justify="space-between">
                    <Grid><Typography variant="body1">Arriving</Typography></Grid>
                    <Grid><Typography variant="body1">{props.booking.start.format(BookingsRangeDisplayFormat)}</Typography></Grid>
                </Grid>
                <Grid style={styles.bookingDetailsProperty} container justify="space-between">
                    <Grid><Typography variant="body1">Leaving</Typography></Grid>
                    <Grid><Typography variant="body1">{props.booking.end ? props.booking.end.format(BookingsRangeDisplayFormat):"Ongoing"}</Typography></Grid>
                </Grid>
            </>
            }



                <Grid style={styles.bookingDetailsProperty} container justify="space-between">
                    <Grid>
                        <Typography variant="body1">
                            Vehicle Registration
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography variant="body1">
                            {props.booking.guest.vehicle.registration}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid style={styles.bookingDetailsProperty} container justify="space-between">
                    <Grid>
                        <Typography variant="body1">
                            Total Earnings
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography variant="body1" color="primary">
                            ${props.booking.earnings.toFixed(2)}    
                        </Typography>    
                    </Grid>
                </Grid>
                <Grid style={styles.bookingDetailsProperty} container justify="space-between">
                    <Grid onClick={() => externalLink(`https://www.sharewithoscar.com.au/incident/` ,`https://www.sharewithoscar.com.au/incident/`)}>
                        <Typography color="primary" variant="body1">
                            Report an incident
                        </Typography>
                    </Grid>
                </Grid>


                {props.booking.approval_status == 1 ?
                  <Grid style={styles.bookingDetailsProperty} container justify="space-between">
                    <Grid>
                      <Typography variant="body1">You have until the end of <b>{strApprovalCutOff}</b> to accept this offer or it will be automatically cancelled.</Typography>
                    </Grid>
                  </Grid>
                :<></>
                }
              </div>
            </div>


            {props.booking.approval_status == 1 ?
              <div className="sd-actions">
                <Button variant="contained" color="primary" onClick={() => props.onDeclineClick(props.booking)}>Decline Booking</Button>
                <Button variant="contained" color="primary" onClick={() => props.onAcceptClick(props.booking)}>Accept Booking</Button>
              </div>             
              :
              <div className="sd-actions">
                {props.booking.booking_type === 2 &&<div className="notetext">Please contact the driver to organise access to the car park before the start date</div>}
                <Button variant="contained" color="primary" onClick={() => props.history.push(`/Messages/${props.booking.reference}/owner/`)}>Contact Driver</Button>
              </div>
            
            }            

        </>
    )
}

function SingleDayBookings(props) {
    return(
        <div style={{backgroundColor: 'white', minHeight: '668px'}}>
            <div className="mainContent topPadded">
                <IconButton className="leftCornerButton" onClick={props.onClose}>
                    <IconBack/>
                </IconButton>
                <Typography style={{marginBottom: '8px'}} variant="display1" color="primary">
                    Bookings on {props.day.format(BookingDayHeader)}
                </Typography>

                <div style={styles.bookingsContainer}>
                    {props.bookings.map((booking, i) => {
                        return(
                            <div key={i} style={styles.upcomingBookingContainer} onClick={() => props.onBookingClick(booking)}>
                                <Grid container >
                                    <Grid item xs={10}> 
                                        <Typography gutterBottom variant="caption">
                                            {booking.start.format(BookingsRangeDisplayFormat)} - {booking.end ? booking.end.format(BookingsRangeDisplayFormat):"Ongoing"}
                                        </Typography>
                                        <Typography variant="body1">
                                            {booking.parking_space.type} on {booking.parking_space.street_name}
                                        </Typography>
                                        <Typography variant="body1" color="primary">
                                            {booking.guest.first_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} style={styles.guestImageContainer}>
                                        {booking.guest.image_user !== null 
                                        ? <img style={styles.guestImage} src={booking.guest.image_user.path} alt="Visitor profile"/>
                                        : <img style={styles.guestImage} src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" alt="Visitor profile"/>}
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )        
}

const ENUM_BOOKINGS_CURRENT = 1;
const ENUM_BOOKINGS_PAST = 2;
const ENUM_BOOKINGS_CANCELLED = 3;

export default class CalendarPage extends Component {
    state = { 
        loading: true, 
        //upcomingBookings: [],
        //pastBookings: [],
        userListings: [],
        allBookings:[],
        bookingsToggle: "upcoming",
        currentTab:ENUM_BOOKINGS_CURRENT,
        
        listingCalendarOpen: false,
        singleDayBookingsOpen: false,
        bookingDetailsOpen: false,
        declineRequestOpen: false,

        openListing: undefined,
        openBookingDay: undefined,
        openBooking: undefined,
        
        listingBookings: undefined,
        bookingsGroupedBySpace: {},
        bookingsGroupedByDay: {}
    }

    // TODO: Replace this garbage!
    componentWillMount() {
        Promise.all([getBookings(), getListings()]).then(([bookingsResponse, listingsResponse]) => {
            //console.log(bookingsResponse, listingsResponse)

            // Make sure all bookings shown are after the current date time
            const now = moment();

            let allBookings = [];
            allBookings.push([]); // ignored
            allBookings.push([]); // ENUM_BOOKINGS_CURRENT
            allBookings.push([]); // ENUM_BOOKINGS_PAST
            allBookings.push([]); // ENUM_BOOKINGS_CANCELLED

            // Process the bookings data
            let bookings = [] ;
            for(let i = 0; i < bookingsResponse.data.length; i++) {
                const raw = bookingsResponse.data[i]

                let booking = {}
                booking.create_date = moment(raw.create_date);
                booking.start = moment(`${raw.start_date} ${raw.start_time}`, ParseDateTimeParameters)
                booking.parking_space = raw.parking_space
                booking.earnings = raw.owner_earnings
                booking.guest = raw.user
                booking.guest.vehicle = raw.vehicle
                booking.reference = raw.reference
                booking.approval_status = raw.approval_status;
                booking.approval_declined_reason = raw.approval_declined_reason;
                booking.booking_type = raw.booking_type;
                booking.headline_amount = raw.headline_amount;
                booking.cancelled = raw.cancelled;
                booking.refunded = raw.refunded;

                // Set keys to filter on later
                booking.spaceId = raw.parking_space.uuid
                booking.bookingDay = booking.start.date()

                //bookings.push(booking)
                if (booking.cancelled || booking.refunded){
                  allBookings[ENUM_BOOKINGS_CANCELLED].push(booking);

                } else if (raw.cease_date){
                  booking.end = moment(`${raw.cease_date} ${raw.cease_time}`, ParseDateTimeParameters)
                  if (booking.end.isBefore(now) /*&& !raw.cancelled*/) {
                    allBookings[ENUM_BOOKINGS_PAST].push(booking);
                  }
                } else {
                  allBookings[ENUM_BOOKINGS_CURRENT].push(booking);
                }

                
            }

            //let pastBookings = [];
            /*for(let i = 0; i < bookingsResponse.data.length; i++) {
                const raw = bookingsResponse.data[i]


                let booking = {}
                if (raw.cease_date){
                  booking.end = moment(`${raw.cease_date} ${raw.cease_time}`, ParseDateTimeParameters)
                  if (booking.end.isAfter(now)) {
                      continue;
                  }
  
                } else {
                  continue; // Ongoing is shown in current
                }

                booking.start = moment(`${raw.start_date} ${raw.start_time}`, ParseDateTimeParameters)
                booking.parking_space = raw.parking_space
                booking.earnings = raw.owner_earnings
                booking.guest = raw.user
                booking.guest.vehicle = raw.vehicle
                booking.reference = raw.reference
                booking.approval_status = raw.approval_status;
                booking.approval_declined_reason = raw.approval_declined_reason;
                booking.booking_type = raw.booking_type;
                booking.headline_amount = raw.headline_amount;
                booking.cancelled = raw.cancelled;
                booking.refunded = raw.refunded;

                // Set keys to filter on later
                booking.spaceId = raw.parking_space.uuid
                booking.bookingDay = booking.start.date()

                //pastBookings.push(booking)
                allBookings[ENUM_BOOKINGS_PAST].push(booking);
                // Limit to 50 most recent past bookings
                // TODO: some kind of paging system to search/view older bookings
                

            }*/


            // Process the listings data
            let listings = []
            for(let i = 0; i < listingsResponse.data.length; i++) {
                const raw = listingsResponse.data[i]

                let listing = {}
                listing.uuid = raw.uuid
                listing.availabilities = raw.availabilityrecurring_set
                listing.spaceType = raw.type
                listing.street = raw.street_name

                listings.push(listing)
            }

            this.setState({
                loading: false,
                allBookings,
                //upcomingBookings: bookings,
                //pastBookings: pastBookings,
                userListings: listings,
                bookingsGroupedBySpace: bookings.reduce(groupBy('spaceId'), {}),
                bookingsGroupedByDay: bookings.reduce(groupBy('bookingDay'), {}),
            })

            if (this.props.match.params.bookingReference !== undefined) {
                const openBooking = bookings.find(elem => elem.reference === this.props.match.params.bookingReference)
                if (openBooking) {
                    this.setState({
                        bookingDetailsOpen: true,
                        openBooking: openBooking
                    })
                }
            }

        }).catch(error => {
            console.log(error)
        })
    }

    handleBookingClick = (booking) => {
        this.setState({
            bookingDetailsOpen: true,
            openBooking: booking
        })
    }
    
    handleCalendarClick = (listing) => {
        const listingBookings = this.state.allBookings[this.state.currentTab].filter(elem => {
            return elem.parking_space.uuid === listing.uuid
        })
        this.setState({
            listingCalendarOpen: true,
            listingBookings: listingBookings,
            openListing: listing
        })
    }
/*
    doDecline = (booking) => {
      console.log("damian test");
      let params = {
        "status": "3" // Declined by Owner
      };

      //this.setState({declineRequestOpen: true});
      setBookingStatus(params, booking.reference).then((response) => {
        //const bookingRef = response.data.reference
        console.log({response});

      }).catch(error => {
        console.log("ERROR:",error);        
      });


    }*/

/*    doAccept = (booking) => {
      console.log("accept");
      //this.setState({declineRequestOpen: true});
      let params = {
        "status": "2" // Approved
      };

      ///const startDate = this.props.start.format(QueryParamDate);
      setBookingStatus(params, booking.reference).then((response) => {
        //const bookingRef = response.data.reference
        console.log({response});

      }).catch(error => {
        console.log("ERROR:",error);        
      });


    }*/


    handleCalendarBookingDayClick = (bookingDate) => {
        //console.log(bookingDate.format())
        const dayBookings = this.state.allBookings[this.state.currentTab].filter(elem => {
            return elem.start.isSame(bookingDate, 'days')
        })
        this.setState({
            singleDayBookingsOpen: true,
            openBookingDay: bookingDate,
            openDayBookings: dayBookings
        })
    }

    render() {
      //console.log("state:", this.state);
      const currentTab = this.state.currentTab;
      const allBookings = this.state.allBookings;   //2d array

      let divBookings = [];

      if (allBookings.length > 1){
        let src = allBookings[currentTab];
     
        // Consistent single load/render for all bookings, regardless of state
        for (let i=0;i<src.length;i++){
          const booking = src[i];
  
          let strClassName = 'li-booking2 noselect';

          let str_end_date;
          if (booking.end){
            str_end_date = booking.end.format(BookingsRangeDisplayFormat)
          } else {
            str_end_date = "Ongoing";
          }

          const strSpaceType = booking.parking_space ? booking.parking_space.type.replace('Parking-lot','Parking Lot') :'?';

          const guestImage = booking.guest.image_user ? <img style={styles.guestImage} src={booking.guest.image_user.path} alt="Visitor profile"/> : <img style={styles.guestImage} src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" alt="Visitor profile"/>;

          let divRate = <div>???</div>;
          if (booking.refunded){
            divRate = (<div className="rate">Refunded</div>);
          } else if (booking.cancelled){
            divRate = (<div className="rate">Cancelled</div>);
          } else {
        
        
            if (booking.booking_type === 1){
              //console.log({booking})
              divRate = (<div className="rate">${booking.headline_amount && booking.headline_amount.toFixed(2)}<IconNext style={{verticalAlign: 'bottom'}}/></div>);
            } else {
              divRate = (<div className="rate">${booking.headline_amount && booking.headline_amount.toFixed(2)}/month<IconNext style={{verticalAlign: 'bottom'}}/></div>);
            }
            
          }
        
          let divApproval;
          //console.log({booking});
          if (booking.refunded || booking.cancelled){
            strClassName+=" cancelled";
          } else if (booking.approval_status == 3 || booking.approval_status == 4){
            divApproval = <div className="approval-declined">{ENUMS.approval_status[booking.approval_status || 0]}</div>;
            strClassName+=" cancelled";
          } else {
            divApproval = <div className="approval-confirmed">{ENUMS.approval_status[booking.approval_status || 0]}</div>;
          }



          divBookings.push(
            <div key={`${currentTab}_${i}`} className={strClassName} onClick={() => {if (booking.approval_status <= 2){ this.handleBookingClick(booking)}}}>
              {guestImage}
              <div className="details">
                {booking.booking_type == 2 && <div className="daterange">{booking.start.format(WPMonthlyBookingsDisplayFormat)} - {str_end_date}</div>}
                {booking.booking_type != 2 && <div className="daterange">{booking.start.format(BookingsRangeDisplayFormat)} - {str_end_date}</div>}

                <h3>[{booking.reference}] <span>{strSpaceType}</span> on {booking.parking_space.street_name}</h3>
                {divApproval}
                {booking.access_code && <p className="access_code">{booking.access_code}</p>}
              </div>
              {divRate}
              {/*booking.approval_status <= 2 && booking.headline_amount && divRate*/}
            </div>);
        }
  
      } 
      
      const ZERO_BOOKINGS_STRING = ['none','You have no upcoming bookings','You have no past bookings','You have no cancelled bookings'];
      if (divBookings.length == 0) {
        divBookings.push(<div key={`${currentTab}_none`} className="calendar-nobookings">{ZERO_BOOKINGS_STRING[currentTab]}</div>);
      }

      return(
            <div style={{height: '100%', overflowY: 'auto'}}>
                <LoadingModal open={this.state.loading} />

                <FullPageModal open={this.state.listingCalendarOpen}>
                    <ListingCalendar 
                        listing={this.state.openListing} 
                        bookings={this.state.listingBookings}
                        onBookingDayClick={this.handleCalendarBookingDayClick}
                        onClose={() => this.setState({listingCalendarOpen: false})}/>
                </FullPageModal>

                <FullPageModal open={this.state.singleDayBookingsOpen}>
                    <SingleDayBookings 
                        day={this.state.openBookingDay} 
                        bookings={this.state.openDayBookings}
                        onClose={() => this.setState({singleDayBookingsOpen: false})}
                        onBookingClick={this.handleBookingClick} />
                </FullPageModal>

                <FullPageModal open={this.state.bookingDetailsOpen}>
                    <BookingDetails 
                        booking={this.state.openBooking} 
                        onClose={() => this.setState({bookingDetailsOpen: false})}
                        //onDeclineClick={this.doDecline}
                        //onAcceptClick={this.doAccept}
                        onAcceptClick={() => this.setState({acceptRequestOpen: true})}
                        onDeclineClick={() => this.setState({declineRequestOpen: true})}
                        history={this.props.history}/>
                </FullPageModal>

                <FullPageModal open={this.state.acceptRequestOpen}>
                  <AcceptRequest
                    history={this.props.history}
                    booking={this.state.openBooking}
                    onClose={() => {
                      //this.updateBooking()
                      this.setState({acceptRequestOpen: false})
                    }}
                  />
                </FullPageModal>

                <FullPageModal open={this.state.declineRequestOpen}>
                  <CancelRequest
                    history={this.props.history}
                    booking={this.state.openBooking}
                    onClose={() => {
                      //this.updateBooking()
                      this.setState({declineRequestOpen: false})
                    }}
                  />
                </FullPageModal>


                <div className="mainContent topPadded" style={{paddingBottom:'50px'}}>
                    <IconButton className="leftCornerButton"
                    component={Link} to="/search">
                        <CloseIcon />
                    </IconButton>

                    <Typography gutterBottom variant="display1" color="primary">
                        Calendar
                    </Typography>
                    
                    
                    <Typography variant="body1">
                        {moment().format(CalendarMonth)}
                    </Typography>
                    <div style={styles.calendarCarouselContainer}>
                        {this.state.userListings.map((listing, i) => {
                            return(
                                <div key={i} onClick={() => this.handleCalendarClick(listing)} style={styles.spaceCalendarContainer}>
                                    <div style={styles.calendarContainerWrapper}>
                                        <div style={styles.calendarContainer}>
                                            <Calendar 
                                                availabilities={listing.availabilities}
                                                bookings={allBookings[ENUM_BOOKINGS_CURRENT].filter(elem => {
                                                    return elem.parking_space.uuid === listing.uuid
                                                })}
                                                maxMonthsToDraw={1}
                                            />
                                        </div>
                                    </div>
                                    <Typography variant="body1">
                                        {listing.spaceType} on
                                    </Typography>
                                    <Typography variant="body1">
                                        {listing.street}
                                    </Typography>
                                </div>
                            )
                        })}
                    </div>

                      <h1 style={{color:'var(--heroColor)',fontSize:'36px'}}>Bookings Received</h1>
                    <div className="calendar-btn-group">
                      <Button variant="contained" className={this.state.currentTab === ENUM_BOOKINGS_CURRENT ? "calendar-btn-selected":"calendar-btn"} onClick={() => this.setState({currentTab:ENUM_BOOKINGS_CURRENT})}>Upcoming Bookings</Button>
                      <Button variant="contained" className={this.state.currentTab === ENUM_BOOKINGS_PAST ? "calendar-btn-selected":"calendar-btn"} onClick={() => this.setState({currentTab:ENUM_BOOKINGS_PAST})}>Past Bookings</Button>
                      <Button variant="contained" className={this.state.currentTab === ENUM_BOOKINGS_CANCELLED ? "calendar-btn-selected":"calendar-btn"} onClick={() => this.setState({currentTab:ENUM_BOOKINGS_CANCELLED})}>Cancelled</Button>
                    </div>
                    
                    <div className="calendar-booking-box">
                      {divBookings}
                    </div>
                    {/*<ToggleButtonGroup
                        value={this.state.bookingsToggle}
                        exclusive
                        onChange={() => {
                            let newValue = (this.state.bookingsToggle === "upcoming") ? "past" : "upcoming";
                            this.setState({bookingsToggle: newValue});
                        }}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="upcoming" aria-label="centered" style={this.state.bookingsToggle === "upcoming" ? styles.toggleButtonActive:styles.toggleButton}>
                            <Typography variant="headline" style={this.state.bookingsToggle === "upcoming" ? {color:'#ffffff',fontWeight:500}:{fontWeight:500}}>
                                Upcoming Bookings
                            </Typography>
                        </ToggleButton>
                        <ToggleButton value="past" aria-label="centered" style={this.state.bookingsToggle === "past" ? styles.toggleButtonActive:styles.toggleButton}>
                            <Typography variant="headline" style={this.state.bookingsToggle === "past" ? {color:'#ffffff',fontWeight:500}:{fontWeight:500}}>
                                Past Bookings
                            </Typography>
                        </ToggleButton>
                        <ToggleButton value="cancelled" aria-label="centered" style={this.state.bookingsToggle === "cancelled" ? styles.toggleButtonActive:styles.toggleButton}>
                            <Typography variant="headline" style={this.state.bookingsToggle === "cancelled" ? {color:'#ffffff',fontWeight:500}:{fontWeight:500}}>
                                Cancelled Bookings
                            </Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>*/}

                    
                    



                    {/*(this.state.currentTab === ENUM_BOOKINGS_CURRENT) ? (
                        <div style={styles.bookingsContainer}>
                        {this.state.allBookings.length > 1 ? allBookings[ENUM_BOOKINGS_CURRENT].map((booking, i) => {
                            let strClassName = "li-booking noselect";
                            //let divApproval = <span style={{backgroundColor:'#ff0000',color:'#ffffff'}}>{ENUMS.approval_status[booking.approval_status || 0]}</span>;
                            
                            let strSpaceType = booking.parking_space ? booking.parking_space.type.replace('Parking-lot','Parking Lot') :'?';
  
                            let str_end_date;
                            if (booking.end){
                              str_end_date = booking.end.format(BookingsRangeDisplayFormat)
                            } else {
                              str_end_date = "Ongoing";
                            }

                            let divRate;
                            if (booking.refunded){
                              divRate = (<div className="rate">Refunded</div>);
                            } else if (booking.cancelled){
                              divRate = (<div className="rate">Cancelled</div>);
                            } else {
                          
                          
                              if (booking.booking_type === 1){
                                //console.log({booking})
                                divRate = (<div className="rate">${booking.headline_amount && booking.headline_amount.toFixed(2)}<IconNext style={{verticalAlign: 'bottom'}}/></div>);
                              } else {
                                divRate = (<div className="rate">${booking.headline_amount && booking.headline_amount.toFixed(2)}/month<IconNext style={{verticalAlign: 'bottom'}}/></div>);
                              }
                              
                            }
                          

                            let divApproval;
                            //console.log({booking});
                            if (booking.refunded || booking.cancelled){
                              strClassName+=" cancelled";
                            } else if (booking.approval_status == 3 || booking.approval_status == 4){
                              divApproval = <div className="approval-declined">{ENUMS.approval_status[booking.approval_status || 0]}</div>;
                              strClassName+=" cancelled";
                            } else {
                              divApproval = <div className="approval-confirmed">{ENUMS.approval_status[booking.approval_status || 0]}</div>;
                            }
                            //onClick={props.booking.approval_status <= 2 ? props.onBookings:null}
                            return(
                                  <div key={i} className={strClassName} onClick={() => {if (booking.approval_status <= 2){ this.handleBookingClick(booking)}}}>
                                    <div className="details">
                                      {booking.booking_type == 2 && <div className="daterange">{booking.start.format(WPMonthlyBookingsDisplayFormat)} - {str_end_date}</div>}
                                      {booking.booking_type != 2 && <div className="daterange">{booking.start.format(BookingsRangeDisplayFormat)} - {str_end_date}</div>}

                                      <h3>[{booking.reference}] <span>{strSpaceType}</span> on {booking.parking_space.street_name}</h3>
                                      {divApproval}
                                      {booking.access_code && <p className="access_code">{booking.access_code}</p>}
                                    </div>
                                    {booking.approval_status <= 2 && booking.headline_amount && divRate}
                                  </div>
                            );
                        })
                        : <div>
                            <Typography variant="body1">
                                There are no upcoming bookings.
                            </Typography>
                        </div>}
                    </div>
                    ) : (<div style={styles.bookingsContainer}>
                        {allBookings.length > 1 ? allBookings[ENUM_BOOKINGS_PAST].map((booking, i) => {
                            return(
                                <div key={i} className="li-booking noselect" onClick={() => this.handleBookingClick(booking)}>
                                    
                                    <Grid container >
                                        <Grid item xs={10}> 

                                            {booking.booking_type == 2 && <Typography variant="caption" color="primary">{booking.start.format(WPMonthlyBookingsDisplayFormat)} -  {booking.end ? booking.end.format(WPMonthlyBookingsDisplayFormat):"Ongoing"}</Typography>}
                                            {booking.booking_type != 2 && <Typography variant="caption" color="primary">{booking.start.format("Do MMMM YYYY h:mm A")} - {booking.end ? booking.end.format("Do MMMM YYYY h:mm A"):"Ongoing"}</Typography>}

                                            <Typography variant="body1">
                                              [{booking.reference}] {booking.parking_space.type} on {booking.parking_space.street_name}
                                            </Typography>
                                            <Typography variant="body1" color="primary">
                                                {booking.guest.first_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} style={styles.guestImageContainer}>
                                            {booking.guest.image_user !== null 
                                            ? <img style={styles.guestImage} src={booking.guest.image_user.path} alt="Visitor profile"/>
                                            : <img style={styles.guestImage} src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" alt="Visitor profile"/>}
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        })
                        : <div>
                            <Typography variant="body1">
                                There are no past bookings.
                            </Typography>
                        </div>}
                    </div>)
                  */} 






                </div>
            </div>
        )
    }
}


class CancelRequest extends Component {
  state = {loading: false}

  doCancelRequest = (booking) => {
    this.setState({loading: true});


    //this.setState({declineRequestOpen: true});
    let params = {
      "status": "3", // Cancelled by Owner
      "reason": this.state.owner_cancel_reason
    };

    ///const startDate = this.props.start.format(QueryParamDate);
    setBookingStatus(params, booking.reference).then((response) => {
      //const bookingRef = response.data.reference
      //console.log({response});
      this.setState({loading: false, cancelRequestSuccessShow:true});

    }).catch(error => {
      console.log("ERROR:",error);        
      this.setState({loading: false})
    });


  }

  externalLink = (target, nativeTarget) => {
    if (isNativeWebview()) {
        const message = JSON.stringify({
            action: "external_link",
            target: nativeTarget
        })
        window.ReactNativeWebView.postMessage(message);
    } else {
        window.location.href = target
    }
}


  render() {

    const thisField = {"isRequired":true, "id":"cancellation_reason", "label":"Reason for declining this booking","type":FieldType.SELECT_DROPDOWN, domain:'owner_monthly_cancellation_reason'};
    let fieldErrors = {};
    

    return(
        <>
        <FullPageModal open={this.state.cancelRequestSuccessShow}>
          <CancelRequestSuccess onClose={() => this.props.history.push(`/search`)}/>
        </FullPageModal>

        <div className="mainContent" style={styles.topColored}>
            <LoadingModal open={this.state.loading}/>

            <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                  <IconBack style={{color: 'white'}}/>
              </IconButton>


            <Typography style={{color: 'white'}} variant="display1">
                  Decline Booking Request
              </Typography>            
        </div>
        <div className="sd-container">
          <div className="sd-details">
          
            <p style={{marginBottom:'20px'}}>Are you sure you want to decline this booking request?</p>
         
            <SelectDropDown pageData={"pageData"} field={thisField} initialValue={this.state.owner_cancel_reason} error={fieldErrors[thisField.id]} onUpdate={(option) => this.setState({owner_cancel_reason:option.finalValue})} />

          </div>
        </div>
        <div className="sd-actions">
            <Button id="db_thumbs_up" style={styles.editButton} fullWidth variant="contained" color="primary"
              onClick={() => this.props.onClose()}>
                Back
            </Button>

            <Button id="db_thumbs_down" fullWidth variant="contained" color="primary"
              disabled={!this.state.owner_cancel_reason}
              onClick={() => this.doCancelRequest(this.props.booking)}>
                Decline Booking
            </Button> 
          </div>

        </>
    );
  }
}

function CancelRequestSuccess(props) {
  return(
    <div className="sd-container">
      <div className="sd-details">

        <Typography variant="display1" color="primary">
          The booking request has been declined
        </Typography>
        <br/>
        <Typography>
          The driver has been notified that you have declined this booking.
        </Typography>
      </div>

      <div className="sd-actions">
        <Button style={styles.alertButton} fullWidth variant="contained" 
          onClick={props.onClose} color="primary">
          Close
        </Button>

      </div>
    </div>
  );
}



class AcceptRequest extends Component {
  state = {loading: false}

  doAcceptRequest = (booking) => {
    this.setState({loading: true});


    //this.setState({declineRequestOpen: true});
    let params = {
      "status": "2", // Approved by Owner
//      "reason": this.state.owner_cancel_reason
    };

    ///const startDate = this.props.start.format(QueryParamDate);
    setBookingStatus(params, booking.reference).then((response) => {
      //const bookingRef = response.data.reference
      //console.log({response});
      this.setState({loading: false, acceptRequestSuccessShow:true});

    }).catch(error => {
      console.log("ERROR:",error);        
      this.setState({loading: false})
    });


  }

  externalLink = (target, nativeTarget) => {
    if (isNativeWebview()) {
        const message = JSON.stringify({
            action: "external_link",
            target: nativeTarget
        })
        window.ReactNativeWebView.postMessage(message);
    } else {
        window.location.href = target
    }
}


  render() {

    //const thisField = {"isRequired":true, "id":"cancellation_reason", "label":"Reason for declining this booking","type":FieldType.SELECT_DROPDOWN, domain:'owner_monthly_cancellation_reason'};
    //let fieldErrors = {};
    

    return(
        <>
        <FullPageModal open={this.state.acceptRequestSuccessShow}>
          <AcceptRequestSuccess onClose={() => this.props.history.push(`/search`)}/>
        </FullPageModal>

        <div className="mainContent" style={styles.topColored}>
            <LoadingModal open={this.state.loading}/>

            <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                  <IconBack style={{color: 'white'}}/>
              </IconButton>


            <Typography style={{color: 'white'}} variant="display1">
                  Confirm Booking Request
              </Typography>            
        </div>
        <div className="sd-container">
          <div className="sd-details">
          
            <p>By clicking 'Confirm Booking' you automatically accept the driver's request to park in your car space.</p>
            
            <p>View the <a href={`https://www.sharewithoscar.com.au/oscar-cancellation-policy/`} target="_blank" className="oscar-external-link">cancellation policy</a> here.</p>

        
            
          </div>
        </div>
        <div className="sd-actions">
            <Button id="db_thumbs_up" style={styles.editButton} fullWidth variant="contained" color="primary"
              onClick={() => this.props.onClose()}>
                Back
            </Button>

            <Button id="db_thumbs_down" fullWidth variant="contained" color="primary"
              onClick={() => this.doAcceptRequest(this.props.booking)}>
                Confirm Booking
            </Button> 
          </div>

        </>
    );
  }
}

function AcceptRequestSuccess(props) {
  return(
    <div className="sd-container">
      <div className="sd-details">

        <Typography variant="display1" color="primary">
          The booking request has been accepted
        </Typography>
        <br/>
        <Typography>
          The driver has been notified that you have accepted this booking.
        </Typography>
    </div>

    <div className="sd-actions">
      <Button style={styles.alertButton} fullWidth variant="contained" 
        onClick={props.onClose} color="primary">
        Close
      </Button>

    </div>
  </div>
  );
}
